<template>
	<transition name="slide">
		<div
			:class="{
				'container-layout': $screen.width >= 992,
				'container-mobile': $screen.width < 992
			}"
			class="oodrive-body"
		>
			<div class="d-flex align-items-center justify-content-center w-100">
				<component :is="getLucideIcon('XCircle')" color="#EA4E2C" :size="32" :stroke-width="3" class="mr-3" />
				<div>
					<div class="oodrive-title">{{ FormMSG(1, 'Failure') }}</div>
					<div class="oodrive-sub-title">{{ FormMSG(2, 'Your electronic signature was failed, please contact your administrator.') }}</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { OODRIVE_RETURN_PAGE_TYPE } from '@/shared/constants';

export default {
	name: 'PageOodriveFailure',
	mixins: [LanguageMessages, GlobalMixin],
	mounted() {
		localStorage.setItem('oodrive_return_page_type', OODRIVE_RETURN_PAGE_TYPE.FAILURE);
	}
};
</script>
<style lang="scss">
.oodrive-body {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	background-color: #f8f9fb;
	.oodrive-title {
		color: rgba(6, 38, 62, 0.74);
		font-size: 2rem;
		font-weight: 600;
		letter-spacing: 0.05em;
	}
	.oodrive-sub-title {
		margin-top: -6px;
		margin-left: 2px;
		color: rgba(6, 38, 62, 0.54);
		letter-spacing: 0.05em;
		font-size: 0.95rem;
	}
}
</style>
